a {
    text-decoration: None;
    color: #0066c0;
}

.p-button-label.p-c {
    font-size: 0.8rem;
    font-family: "AvenirNextLTPro,Helvetica,sans-serif";
    font-weight: 600;
}

.p-menuitem-text {
    text-align: left;
}
.p-slidemenu .p-slidemenu-content {
    height: 90% !important;
    font-family: "AvenirNextLTPro,Helvetica,sans-serif";
    font-weight: 600;
    font-size: 0.8rem;
}

.p-slidemenu-backward {
    font-size: 1rem;
    font-family: "AvenirNextLTPro,Helvetica,sans-serif";
    font-weight: 600;
}

.p-submenu-list > li:first-child .p-menuitem-text {
    font-size: 14px;
    color: #0066c0;
}
/* @media only screen and (max-width: 600px) {
    .p-button-label.p-c {
        display: none !important;
    }
    .pi.pi-bars.p-c.p-button-icon-left {
        margin-right: 0;
    }
} */